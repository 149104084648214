import ENUMS from 'common/enums';
import defaultImageHost from 'common/helpers/defaultImageHost';
import { transformFormToObject, transformObjectToFormState } from 'common/helpers/formStore';
import handleError from 'common/helpers/handleError';
import Vue from 'vue';

const state = () => ({
  form: undefined,
});

let abortController;

const actions = {
  async fetchForm({ commit }, guid) {
    const { data } = await this.$axios.get(`forms/${guid}`);
    const value = await transformObjectToFormState(data.data);
    commit('updateStateForm', { value });
  },

  async updateForm({ commit, state }, body) {
    try {
      const { form } = state;

      if (abortController) {
        abortController.abort();
      }
      abortController = new AbortController();

      const data = transformFormToObject({
        ...form,
        ...body,
      });
      if (data.thankYouPageWidgetsWebinarHostImage === defaultImageHost) {
        data.thankYouPageWidgetsWebinarHostImage = '';
      }
      const { data: response } = await this.$axios.put(`forms/${form[ENUMS.STEPS.OTHER].guid}`, data, { signal: abortController.signal });
      const value = await transformObjectToFormState(response.data);
      commit('updateStateForm', { value });
    } catch (error) {
      handleError(error);
    }
  },
};

const getters = {
  getFormPart: ({ form }) => (key) => ({ ...form[key] }),
};

const mutations = {
  updateStateForm(state, { value, key = undefined }) {
    if (key === undefined) {
      Vue.set(state, 'form', value);
    } else {
      Vue.set(state.form, key, {
        ...state.form[key],
        ...value,
      });
    }
  },
  clear(state) {
    state.form = undefined;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
