const POPUP_BUTTON = 'popupButton';
const POPUP = 'popup';
const STEP1 = 'step1';
const STEP2 = 'step2';
const THANK_PAGE = 'thankPage';

export default {
  POPUP_BUTTON,
  POPUP,
  STEP1,
  STEP2,
  THANK_PAGE,
};
