import { setCookie, unsetTokens } from 'common/helpers/cookies';
import Cookies from 'js-cookie';
import Vue from 'vue';

const state = () => ({
  account: undefined,
  redirect: undefined,
  accessToken: Cookies.get('accessToken'),
  layout: undefined,
});

const getters = {
  isAuth: (state) => !!state.account,
  isActiveAccount: ({ account }) => !!account?.isAccountActive,
  defaultLanguage: ({ layout }) => layout?.availableLanguages.find((lang) => lang.default).locale,
  getBanner: ({ layout }) => (type, location) => layout?.banners.find((item) => item.template === type && item.location === location),
  getProducts: ({ account, layout }) => (type) => {
    const products = layout?.products?.[type] || [];
    const { paymentEmail, firstName, lastName } = account;
    if (Array.isArray(products)) {
      return products.map((product) => ({
        ...product,
        url: `${product.url}?email=${paymentEmail}&first_name=${firstName}&last_name=${lastName}`,
      }));
    }
    if (Object.keys(products).length > 0) {
      return {
        ...products,
        url: `${products.url}?email=${paymentEmail}&first_name=${firstName}&last_name=${lastName}`,
      };
    }
    return [];
  },
};

const mutations = {
  setAccount(state, account) {
    Vue.set(state, 'account', account);
  },
  setRedirect(state, url) {
    Vue.set(state, 'redirect', url);
  },
  setLayout(state, payload) {
    Vue.set(state, 'layout', payload);
  },
  clear(state) {
    state.account = undefined;
    state.redirect = undefined;
    state.accessToken = undefined;
    state.layout = undefined;
  },
};

const authStack = async function ({
  dispatch,
  data,
  options = {},
}) {
  setCookie('accessToken', data.token, {
    domain: process.env.COOKIE_HOST,
    ...options,
  });
  await dispatch('identity');
};

const actions = {
  async auth({ dispatch }, form) {
    const { data } = await this.$axios.post('/user/auth', form);
    await authStack({
      data: data.data,
      dispatch,
      options: {
        expires: form.remember ? 365 : 7,
      },
    });
  },
  async signup({ dispatch }, form) {
    const { data } = await this.$axios.post('/auth/signup', form);
    await authStack({
      data: data.data,
      dispatch,
    });
  },
  async identity({ commit }) {
    const { data } = await this.$axios.get('/user/identity');
    commit('setAccount', data.data);
  },
  async updateIdentity({ commit }, form) {
    const { data } = await this.$axios.put('user/identity', form);
    commit('setAccount', data.data);
  },
  async confirmEmail({ commit }, confirmCode) {
    const { data } = await this.$axios.put('user/change/email/confirm', { confirmCode });
    commit('setAccount', data.data);
  },
  async changeApiKey({ commit }) {
    const { data } = await this.$axios.put('user/change/apikey');
    commit('setAccount', data.data);
  },
  async getLayout({ commit }) {
    try {
      const { data } = await this.$axios.get('public/layout');
      commit('setLayout', data.data);
    } catch (error) {
      console.error(error);
    }
  },
  logout({ commit }) {
    // Clear stores
    commit('clear');
    commit('dashboard/clear', undefined, { root: true });
    commit('form/clear', undefined, { root: true });

    unsetTokens(undefined, process.env.COOKIE_HOST);
  },
};

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state,
};
