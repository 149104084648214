import * as AUTORESPONDER_TYPES from 'common/enums/autoresponderTypes';
import FOLDER_TYPES from 'common/enums/folderTypes';
import * as PAGE_BLOCKS from 'common/enums/pageBlocks';
import PREVIEW_PAGE_TABS from 'common/enums/previewPageTabs';
import * as STEPS from 'common/enums/steps';
import * as WEBINAR_TYPES from 'common/enums/webinarTypes';
import * as BANNER_LOCATIONS from './bannerLocations';
import * as BANNER_TYPES from './bannerTypes';
import * as EVENTS from './events';
import * as NOTIFICATION_TYPES from './notificationTypes';
import RENDER_TABS from './renderTabs';
import RENDER_TABS_NAMES from './renderTabsNames';
import * as ROUTES from './routes';
import TIME_ZONES from './timezones';
import TOGGLE_FILED_NAMES from './toggleFieldsNames';
import * as WIDGET_TYPES from './widgetTypes';

export default {
  AUTORESPONDER_TYPES,
  BANNER_LOCATIONS,
  BANNER_TYPES,
  EVENTS,
  FOLDER_TYPES,
  NOTIFICATION_TYPES,
  PAGE_BLOCKS,
  PREVIEW_PAGE_TABS,
  RENDER_TABS,
  RENDER_TABS_NAMES,
  ROUTES,
  STEPS,
  TIME_ZONES,
  TOGGLE_FILED_NAMES,
  WEBINAR_TYPES,
  WIDGET_TYPES,
};
