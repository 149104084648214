export default {
  path: 'form/:guid',
  props: true,
  component: () => import('layouts/FormEditLayout.vue'),
  name: ENUMS.ROUTES.FORM.INDEX,
  redirect: { name: ENUMS.ROUTES.FORM.SETTING_PAGE },
  children: [
    {
      name: ENUMS.ROUTES.FORM.SETTING_PAGE,
      meta: { subscription: true },
      path: 'setting',
      component: () => import('pages/dashboard/form/SettingPage.vue'),
    },
    {
      name: ENUMS.ROUTES.FORM.FORM_FIELDS_PAGE,
      meta: { subscription: true },
      path: 'form-fields',
      component: () => import('pages/dashboard/form/FormFieldsPage.vue'),
    },
    {
      name: ENUMS.ROUTES.FORM.NOTIFICATIONS_PAGE,
      meta: { subscription: true },
      path: 'notifications',
      component: () => import('pages/dashboard/form/NotificationsPage.vue'),
    },
    {
      name: ENUMS.ROUTES.FORM.THANK_YOU_PAGE,
      meta: { subscription: true },
      path: 'thank-you',
      component: () => import('pages/dashboard/form/ThankYouPage.vue'),
    },
    {
      name: ENUMS.ROUTES.FORM.AUTORESPONDERS_PAGE,
      meta: { subscription: true },
      path: 'autoresponders',
      component: () => import('pages/dashboard/form/AutorespondersPage.vue'),
    },
    {
      name: ENUMS.ROUTES.FORM.DESIGN_PAGE,
      meta: { subscription: true },
      path: 'design',
      component: () => import('pages/dashboard/form/DesignPage.vue'),
    },
    {
      name: ENUMS.ROUTES.FORM.VALIDATION_PAGE,
      meta: { subscription: true },
      path: 'validation',
      component: () => import('pages/dashboard/form/ValidationPage.vue'),
    },
    {
      name: ENUMS.ROUTES.FORM.CODE_PAGE,
      meta: { subscription: true },
      path: 'code',
      component: () => import('pages/dashboard/form/CodePage.vue'),
    },
  ],
};
