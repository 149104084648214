import { i18n } from 'common/boot/i18n';
import RENDER_TABS_NAMES from 'src/enums/renderTabsNames';

export default {
  POPUP_TABS: [
    {
      label: i18n.t('label.popupCTA'),
      value: RENDER_TABS_NAMES.POPUP_BUTTON,
    },
    {
      label: i18n.t('label.popup'),
      value: RENDER_TABS_NAMES.POPUP,
    },
  ],
  MULTISTEP_TABS: [
    {
      label: i18n.t('label.step1'),
      value: RENDER_TABS_NAMES.STEP1,
    },
    {
      label: i18n.t('label.step2'),
      value: RENDER_TABS_NAMES.STEP2,
    },
  ],
  STEP_TAB:
    {
      label: i18n.t('label.step1'),
      value: RENDER_TABS_NAMES.STEP1,
    },

  THANK_TAB: {
    label: i18n.t('label.thankPage'),
    value: RENDER_TABS_NAMES.THANK_PAGE,
  },

};
