import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import useRouter from 'src/router';
import useStore from 'src/store';
import Vue from 'vue';

const store = useStore();
const router = useRouter(store);
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: process.env.SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', /^\//],
      }),
    ],
    environment: process.env.APP_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // eslint-disable-next-line unicorn/no-zero-fractions
    tracesSampleRate: 0.2,
    tracingOptions: {
      trackComponents: true,
    },
  });
}
