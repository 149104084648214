import { i18n } from 'common/boot/i18n';

const routes = [
  {
    path: '/auth',
    component: () => import('layouts/AuthLayout.vue'),
    children: [
      {
        name: ENUMS.ROUTES.AUTH.SIGNIN,
        path: '',
        meta: { guestOnly: true },
        props: () => ({
          title: i18n.t('title.welcomeToWebbymize'),
        }),
        component: () => import('common/pages/auth/AuthPage'),
      },
      {
        name: ENUMS.ROUTES.AUTH.RESTORE,
        path: 'restore',
        meta: { guestOnly: true },
        component: () => import('common/pages/auth/RestorePage.vue'),
      },
      {
        name: ENUMS.ROUTES.AUTH.RESET_PASSWORD,
        path: 'restore-password/confirm/:code',
        meta: { guestOnly: true },
        component: () => import('common/pages/auth/ResetPasswordPage.vue'),
      },
      {
        name: ENUMS.ROUTES.AUTH.SIGNUP,
        path: 'signup',
        meta: { guestOnly: true },
        component: () => import('pages/SignUpPage.vue'),
      },
    ],
  },
];

export default routes;
