const state = () => ({
  preview: {},
});

const actions = {
  async getPreview({ commit }, pageId) {
    try {
      const { data } = (await this.$axios.get(`/public/pages/${pageId}.json`)).data;
      commit('setPreview', data);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  setPreview(state, data) {
    state.preview = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
