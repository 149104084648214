import emailWorkflow from 'common/store/emailWorkflow';
import smtp from 'common/store/smtp';
import Vue from 'vue';
import Vuex from 'vuex';
import app from './app';
import dashboard from './dashboard';
import form from './form';
import page from './page';
import preview from './preview';

Vue.use(Vuex);

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default function (/* { ssrContext } */) {
  return new Vuex.Store({
    modules: {
      app,
      dashboard,
      emailWorkflow,
      form,
      page,
      preview,
      smtp,
    },

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEBUGGING,
  });
}
