const WEBINAR = 'webinar';
const EMAIL = 'email';
const NAME = 'name';
const PHONE = 'phone';
const SUBMIT_BUTTON = 'submitButton';

export default {
  WEBINAR,
  EMAIL,
  NAME,
  PHONE,
  SUBMIT_BUTTON,
};
