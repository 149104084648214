export default {
  name: ENUMS.ROUTES.INDEX,
  path: 'dashboard',
  component: () => import('pages/dashboard/IndexPage'),
  children: [
    {
      name: ENUMS.ROUTES.DASHBOARD.INDEX,
      path: '',
      meta: { auth: true },
      component: () => import('pages/dashboard/DashboardPage'),
    },
    {
      name: ENUMS.ROUTES.DASHBOARD.AUTORESPONDERS,
      path: 'autoresponders',
      component: () => import('common/pages/dashboard/AutoresponderPage'),
    },
    {
      name: ENUMS.ROUTES.DASHBOARD.WEBINARS,
      path: 'webinars',
      component: () => import('pages/dashboard/WebinarPage'),
    },
    {
      path: 'webinars/zoom-integration',
      alias: '/webinars/zoom-integration',
      name: ENUMS.ROUTES.DASHBOARD.ZOOM_INTEGRATION,
      component: () => import('pages/dashboard/ZoomIntegrationPage'),
    },
    {
      name: ENUMS.ROUTES.DASHBOARD.PAGES,
      path: 'pages',
      component: () => import('pages/dashboard/PagesPage'),
    },
    {
      name: ENUMS.ROUTES.DASHBOARD.SMTP,
      path: 'smtp',
      component: () => import('common/pages/dashboard/SmtpPage'),
    },
    {
      name: ENUMS.ROUTES.DASHBOARD.AFFILIATES,
      path: 'my-affiliates',
      component: () => import('pages/dashboard/AffiliatesPage'),
    },
  ],
};
