<template>
  <div id="q-app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App',
  computed: {
    showSupportWidget() {
      return ![
        ENUMS.ROUTES.PAGE.PREVIEW_THANK_YOU,
        ENUMS.ROUTES.PAGE.PREVIEW,
      ].includes(this.$route.name);
    },
  },
  async created() {
    // eslint-disable-next-line consistent-return
    this.$q.iconMapFn = (name) => {
      if (name.startsWith('fl:')) {
        return {
          cls: `fl-${name.slice(3)}`,
        };
      }
    };
  },
  mounted() {
    if (!this.showSupportWidget) {
      return;
    }
    document.title = 'Webbymyze';
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=67eaa31b-aa73-4f58-9664-e194848bbc0e';
    document.head.append(script);
  },
};
</script>
