import auth from 'src/router/auth';
import dashboard from 'src/router/dashboard';
import form from 'src/router/form';
import profile from 'src/router/profile';

const routes = [
  {
    name: ENUMS.ROUTES.LANDING,
    path: '/',
    component: () => import('pages/landing/LandingPage'),
  },
  {
    path: '/',
    component: () => import('layouts/DashboardLayout.vue'),
    children: [
      dashboard,
      profile,
      form,
      {
        name: ENUMS.ROUTES.DEMO.INDEX,
        path: 'demo',
        component: () => import('pages/demo/IndexPage.vue'),
      },
    ],
  },
  ...auth,
  {
    path: '/form/:guid/preview',
    name: ENUMS.ROUTES.FORM.PREVIEW,
    props: true,
    meta: { auth: true },
    component: () => import('layouts/FormPreviewLayout.vue'),
  },
  {
    path: '/page/preview/thank-you/:pageGuid',
    name: ENUMS.ROUTES.PAGE.PREVIEW_THANK_YOU,
    props: true,
    component: () => import('pages/PreviewThankYouPage.vue'),
  },
  {
    path: '/page/preview/:pageGuid',
    name: ENUMS.ROUTES.PAGE.PREVIEW,
    props: true,
    component: () => import('pages/PreviewPage.vue'),
  },
  {
    name: ENUMS.ROUTES.DASHBOARD.PAGE_TEMPLATES,
    path: '/pages/templates',
    component: () => import('pages/dashboard/TemplatesPage'),
  },
  {
    name: ENUMS.ROUTES.DASHBOARD.VIEW_PAGE,
    path: '/pages/:pageGuid',
    props: true,
    meta: { auth: true },
    component: () => import('pages/dashboard/PageBuilderPage.vue'),
  },
  {
    name: ENUMS.ROUTES.UNSUBSCRIBE,
    path: '/email-workflows/:workflowGuid/unsubscribe/:leadGuid',
    props: true,
    component: () => import('common/pages/UnsubscribePage'),
  },
  {
    path: '*',
    component: () => import('layouts/DashboardLayout.vue'),
    children: [
      {
        path: '',
        name: ENUMS.ROUTES.NOT_FOUND,
        component: () => import('common/pages/Error404Page.vue'),
      },
    ],
  },
];

export default routes;
