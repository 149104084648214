const INDEX = 'index';
const NOT_FOUND = 'not_found';

const FORM = {
  INDEX: 'form_index',
  SETTING_PAGE: 'form_setting-page',
  FORM_FIELDS_PAGE: 'form_form-fields-page',
  NOTIFICATIONS_PAGE: 'form_notifications-page',
  THANK_YOU_PAGE: 'form_thank-you-page',
  AUTORESPONDERS_PAGE: 'form_autoresponders-page',
  DESIGN_PAGE: 'form_design-page',
  VALIDATION_PAGE: 'form_validation-page',
  CODE_PAGE: 'form_code-page',
  PREVIEW: 'form_preview',
};

const DASHBOARD = {
  INDEX: 'dashboard_index',
  AUTORESPONDERS: 'dashboard_autoresponders',
  WEBINARS: 'dashboard_webinars',
  PAGES: 'dashboard_pages',
  PAGE_TEMPLATES: 'dashboard_page-templates',
  VIEW_PAGE: 'dashboard_view-page',
  SMTP: 'dashboard_smtp',
  AFFILIATES: 'dashboard_affiliates',
};

const DEMO = {
  INDEX: 'demo_index',
};

const AUTH = {
  SIGNUP: 'auth_signup',
  SIGNIN: 'auth_signin',
  RESTORE: 'auth_restore',
  RESET_PASSWORD: 'auth_reset',
};

const PROFILE = {
  INDEX: 'profile_index',
  DATA: 'profile_data',
  ACCESS_DATA: 'profile_access-data',
  CONFIRM_EMAIL: 'profile_confirm-email',
};

const PAGE = {
  PREVIEW: 'page-preview',
  PREVIEW_THANK_YOU: 'page-preview-thank-you',
};

const UNSUBSCRIBE = 'unsubscribe';
const LANDING = 'landing';
export {
  AUTH,
  DASHBOARD,
  DEMO,
  FORM,
  INDEX,
  LANDING,
  NOT_FOUND,
  PAGE,
  PROFILE,
  UNSUBSCRIBE,
};
