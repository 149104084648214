import handleError from 'common/helpers/handleError';
import Vue from 'vue';

const initialState = () => ({
  previewTab: ENUMS.PREVIEW_PAGE_TABS.PAGE,
  page: undefined,
});

const state = () => initialState();

const actions = {
  async fetchPage({ commit }, guid) {
    const { data } = await this.$axios.get(`pages/${guid}`);
    commit('setPage', data.data);
  },
  async updatePage({ commit, state }, body) {
    try {
      const form = { ...body };
      form.digistoreContentLink = form.contentLinks?.digistore;
      delete form.form;
      delete form.contentLinks;
      const { data } = await this.$axios.put(`pages/${state.page.guid}`, form);
      commit('setPage', data.data);
    } catch (error) {
      handleError(error);
    }
  },
};

const mutations = {
  addWorkflowToPage(state, guid) {
    Vue.set(state.page, 'emailWorkflow', { guid });
  },
  setPreviewTab(state, tab) {
    state.previewTab = tab;
  },
  setPage(state, page) {
    Vue.set(state, 'page', {
      ...page,
      formGuid: page.form?.guid,
    });
  },
  clear(state) {
    const clearState = initialState();
    Object.keys(clearState).forEach((key) => Vue.set(state, key, clearState[key]));
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
