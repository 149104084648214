export default {
  name: ENUMS.ROUTES.PROFILE.INDEX,
  path: 'profile',
  component: () => import('layouts/ProfileLayout'),
  redirect: { name: ENUMS.ROUTES.PROFILE.DATA },
  children: [
    {
      name: ENUMS.ROUTES.PROFILE.DATA,
      path: 'my-data',
      component: () => import('common/pages/profile/MyDataPage'),
    },
    {
      name: ENUMS.ROUTES.PROFILE.ACCESS_DATA,
      path: 'access-data',
      component: () => import('common/pages/profile/AccessDataPage'),
    },
    {
      name: ENUMS.ROUTES.PROFILE.CONFIRM_EMAIL,
      path: 'email/confirm/:guid',
      props: true,
      component: () => import('pages/profile/ConfirmEmailPage'),
    },
  ],
};
