import Vue from 'vue';

const defaultState = () => ({
  forms: [],
  webinars: [],
  autoresponders: [],
  affiliates: [],
  pages: [],
  pageTemplates: [],
  smtpList: [],
  folders: {
    [ENUMS.FOLDER_TYPES.FORM]: [],
    [ENUMS.FOLDER_TYPES.PAGE]: [],
  },
  selectedFolders: {
    [ENUMS.FOLDER_TYPES.FORM]: undefined,
    [ENUMS.FOLDER_TYPES.PAGE]: undefined,
  },
  isCopied: false,
});

const state = () => defaultState();

const storeGetters = {
  getFolders: (state) => (type) => state.folders[type],
  getSelectedFolders: (state) => (type) => state.selectedFolders[type],
};

const actions = {
  // Forms
  async fetchForms({ rootState, commit, getters }) {
    if (!rootState.app.account.capabilities.canManageForm) {
      return;
    }
    const { data } = await this.$axios.get('forms', {
      params: {
        folderGuids: getters.getSelectedFolders(ENUMS.FOLDER_TYPES.FORM),
      },
    });
    commit('setForms', data.data.elements);
  },
  async createForm({ rootState, dispatch }) {
    if (!rootState.app.account.capabilities.canManageForm) {
      return undefined;
    }
    const { data } = await this.$axios.post('forms');
    await dispatch('fetchForms');
    return data.data.guid;
  },
  async updateStatusForm({ dispatch }, { guid, body }) {
    await this.$axios.post(`forms/${guid}/status`, body);
    await dispatch('fetchForms');
  },
  async removeForm({ dispatch }, guid) {
    await this.$axios.delete(`forms/${guid}`);
    await dispatch('fetchForms');
  },

  // Webinars
  async fetchWebinars({ rootState, commit }) {
    if (!rootState.app.account.capabilities.canManageForm) {
      return;
    }
    const { data } = await this.$axios.get('webinars');
    commit('setWebinars', data.data.elements);
  },
  async createWebinar({ rootState, dispatch }, webinar) {
    if (!rootState.app.account.capabilities.canManageForm) {
      return undefined;
    }
    const { data } = await this.$axios.post('webinars', webinar);
    await dispatch('fetchWebinars');
    return data;
  },
  async updateWebinar({ dispatch }, { id, body }) {
    await this.$axios.put(`webinars/${id}`, body);
    await dispatch('fetchWebinars');
  },
  async removeWebinar({ dispatch }, id) {
    await this.$axios.delete(`webinars/${id}`);
    await dispatch('fetchWebinars');
  },

  // Autoresponders
  async fetchAutoresponders({ commit }) {
    const { data } = await this.$axios.get('autoresponders');
    commit('setAutoresponders', data.data.elements);
  },
  async createAutoresponder({ dispatch }, autoresponder) {
    const { data } = await this.$axios.post('autoresponders', autoresponder);
    await dispatch('fetchAutoresponders');
    return data;
  },
  async updateAutoresponder({ dispatch }, { id, body }) {
    await this.$axios.put(`autoresponders/${id}`, body);
    await dispatch('fetchAutoresponders');
  },
  async removeAutoresponder({ dispatch }, id) {
    await this.$axios.delete(`autoresponders/${id}`);
    await dispatch('fetchAutoresponders');
  },

  // Affiliates
  async fetchAffiliates({ commit }) {
    const { data } = await this.$axios.get('affiliates');
    commit('setAffiliates', data.data);
  },

  async removeAffiliate({ dispatch }, id) {
    await this.$axios.delete('affiliates', { data: { affiliatedUserId: id } });
    await dispatch('fetchAffiliates');
  },

  // Page templates
  async fetchPageTemplates({ commit }) {
    const { data } = await this.$axios.get('pages/templates');
    commit('setPageTemplates', data.data);
  },

  // Pages
  async fetchPages({ rootState, commit, getters }) {
    if (!rootState.app.account.capabilities.canManagePage) {
      return;
    }
    const { data } = await this.$axios.get('pages', {
      params: {
        folderGuids: getters.getSelectedFolders(ENUMS.FOLDER_TYPES.PAGE),
      },
    });
    commit('setPages', data.data.elements);
  },
  async createPage({ rootState, dispatch }, templateId) {
    if (!rootState.app.account.capabilities.canManagePage) {
      return undefined;
    }
    const { data } = await this.$axios.post('pages', {
      templateId,
    });
    await dispatch('fetchPages');
    return data.data.guid;
  },
  async deletePage({ dispatch }, guid) {
    await this.$axios.delete(`pages/${guid}`);
    await dispatch('fetchPages');
  },
  async clonePage({ dispatch }, guid) {
    await this.$axios.post(`pages/${guid}/duplicate`);
    await dispatch('fetchPages');
  },
  async setPagePublicity({ state, commit, rootState }, { guid, publish }) {
    const { data } = await this.$axios.put(`pages/${guid}/publish`, { publish });
    commit('setPages', state.pages.map((value) => (value.guid === guid ? data.data : value)));
    if (rootState.page.page?.guid === guid) {
      commit('page/setPage', {
        ...rootState.page.page,
        ...data.data,
      }, { root: true });
    }
  },

  // Folders
  async fetchFolders({ commit }, { type, params }) {
    const { data } = await this.$axios.get(`folders/${type}`, { params });
    commit('setFolders', { type, payload: data.data.elements });
  },
};

const mutations = {
  setForms(state, payload) {
    state.forms = payload;
  },
  setWebinars(state, payload) {
    state.webinars = payload;
  },
  setAutoresponders(state, payload) {
    state.autoresponders = payload;
  },
  setAffiliates(state, payload) {
    state.affiliates = payload;
  },
  setCopied(state, payload) {
    state.isCopied = payload;
  },
  setPages(state, payload) {
    state.pages = payload;
  },
  setPageTemplates(state, payload) {
    state.pageTemplates = payload;
  },
  setFolders(state, { type, payload }) {
    state.folders[type] = payload;
  },
  setSelectedFolders(state, { type, payload }) {
    Vue.set(state.selectedFolders, type, payload);
  },
  clear(state) {
    const clearState = defaultState();
    Object.keys(clearState).forEach((key) => {
      state[key] = clearState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters: storeGetters,
  mutations,
};
